.btn-container {
  visibility: hidden;
}

@font-face {
  font-family: Consolas;
  src: url("https://moroccantapes.b-cdn.net/static/Consolas.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: Consolas;
  src: url("https://moroccantapes.b-cdn.net/static/Consolas-Bold.ttf") format("truetype");
  font-weight: 700;
}

:root {
  --bckgdColor: #1e1d1c;
  --offBckgd: #161514;
  --sideBarColor: #3a3834;
  --offWhite: #fff6d9;
  --mainColor: #000;
  --secondaryColor: #ffdd63;
  --red: #fc1d0b;
  --green: #44a276;
  --textColor: #fff;
  --textSecondaryColor: #737373;
  --playerColor: #161514bf;
  --mobilePadding: 42px;
  --vh: 1vh;
}

*, :after, :before {
  box-sizing: border-box;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

::-webkit-scrollbar {
  display: none;
}

.flex-container {
  display: flex;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.flex-col {
  flex-direction: column;
}

.no-display {
  display: none !important;
}

.no-pointers {
  pointer-events: none !important;
}

.no-scroll {
  overflow: hidden !important;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
}

.bckg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bckg-right {
  background-position: 100% !important;
}

.ease-out {
  -o-transition: .3s ease-out;
  transition: all .3s ease-out;
}

.cubic-bezier {
  -o-transition: .7s cubic-bezier(.57, -.21, 0, .74);
  transition: all .7s cubic-bezier(.57, -.21, 0, .74);
}

.error {
  color: var(--red) !important;
}

.btns {
  cursor: pointer;
  border: none;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  outline: none;
  font-family: Consolas, sans-serif;
  display: block;
}

.box-theme {
  background-color: var(--red);
  text-align: center;
  color: var(--mainColor);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: block;
}

.transform-sbleft {
  transform: translateX(-255px);
}

.transform-sbright {
  transform: translateX(255px);
}

.transform-y-100 {
  transform: translateY(100%);
}

.transform-header-100 {
  transform: translateX(-100%);
}

.transform-sbright-100 {
  transform: translateX(100%);
}

.mask-gradient-5 {
  -webkit-mask-image: linear-gradient(to right, transparent 0, var(--playerColor) 5px, var(--playerColor) 80%, transparent 100%);
  -webkit-mask-image: linear-gradient(to right, transparent 0, var(--playerColor) 5px, var(--playerColor) 80%, transparent 100%);
  mask-image: linear-gradient(to right, transparent 0, var(--playerColor) 5px, var(--playerColor) 80%, transparent 100%);
}

.mask-gradient-20 {
  -webkit-mask-image: linear-gradient(to bottom, transparent 0, var(--playerColor) 20px, var(--playerColor) 80%, transparent 100%);
  -webkit-mask-image: linear-gradient(to bottom, transparent 0, var(--playerColor) 20px, var(--playerColor) 80%, transparent 100%);
  mask-image: linear-gradient(to bottom, transparent 0, var(--playerColor) 20px, var(--playerColor) 80%, transparent 100%);
}

.mask-gradient-5-perc {
  -webkit-mask-image: linear-gradient(to right, transparent 0, var(--playerColor) 5%, var(--playerColor) 80%, transparent 100%);
  -webkit-mask-image: linear-gradient(to right, transparent 0, var(--playerColor) 5%, var(--playerColor) 80%, transparent 100%);
  mask-image: linear-gradient(to right, transparent 0, var(--playerColor) 5%, var(--playerColor) 80%, transparent 100%);
}

button:disabled {
  opacity: .8;
  cursor: default;
}

.comment-form .btn-disabled {
  background-color: var(--bckgdColor) !important;
}

#subscribeForm .btn-disabled, #unsubscribeForm .btn-disabled {
  background-color: var(--secondaryColor) !important;
}

.spinner, .spinner:before {
  border-radius: 50%;
}

.spinner {
  color: var(--red);
  text-indent: -99999px;
  width: 20px;
  height: 20px;
  margin: 0 auto;
  font-size: 22px;
  position: relative;
  overflow: hidden;
  transform: translateZ(0);
  box-shadow: inset 0 0 0 2px;
}

.comment-form .spinner {
  color: var(--secondaryColor) !important;
}

#subscribeForm .spinner, #unsubscribeForm .spinner {
  color: var(--bckgdColor) !important;
}

.spinner:before {
  content: "";
  background-color: var(--textColor);
  transform-origin: 10.4px 10.2px;
  -webkit-animation: 2s 1.5s infinite loading;
  border-radius: 20.4px 0 0 20.4px;
  width: 10.4px;
  height: 20.4px;
  animation: 1s linear infinite loading;
  position: absolute;
  top: -.2px;
  left: -.2px;
}

.comment-form .spinner:before {
  background-color: var(--bckgdColor) !important;
}

#subscribeForm .spinner:before, #unsubscribeForm .spinner:before {
  background-color: var(--secondaryColor) !important;
}

@keyframes loading {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.grecaptcha-badge {
  opacity: 0;
  z-index: -1;
  right: 0 !important;
}

html, body {
  -webkt-scroll-behavior: smooth;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden scroll;
}

html {
  background-color: var(--bckgdColor);
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
  font-family: Consolas, sans-serif;
}

.error-overlay {
  background-color: var(--red) !important;
}

#infoWrapper {
  width: 100vw;
  height: 100vh;
}

#infoWrapper img {
  width: 100vw;
  max-width: 1000px;
}

#boxWrapper {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
}

#box {
  text-align: center;
  opacity: 0;
  border-radius: 6px;
  width: calc(100% - 40px);
  max-width: 420px;
  min-height: 1px;
  padding: 20px;
  display: none;
  background-color: #3b3935b3 !important;
}

#box a:link, #box a:visited {
  color: var(--secondaryColor);
  font-weight: 700;
  text-decoration: underline;
}

#box h1, #box h2, #box p {
  color: var(--textColor);
}

#unsubscribeSubmitBtn {
  background-color: var(--secondaryColor);
  width: 100%;
  max-width: 200px;
  height: 40px;
  color: var(--bckgdColor);
  padding: 0 2em;
  font-size: 1em;
  font-weight: 700;
  line-height: 1em;
}

#bigLogo {
  width: 100%;
  height: calc(100 * var(--vh));
  position: sticky;
  top: 0;
}

#bigLogo img {
  width: calc(100% - 60px);
  max-width: 1000px;
}

#fixedContainer {
  z-index: 4;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  width: 100%;
  position: fixed;
  top: 0;
}

#header {
  width: 285px;
  height: calc(100 * var(--vh));
  background-color: var(--sideBarColor);
  z-index: 5;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  position: absolute;
  top: 0;
  left: 0;
  overflow-y: auto;
  box-shadow: 1px 0 5px #00000080;
}

.drawer {
  width: 30px;
  height: calc(100 * var(--vh));
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  color: var(--textColor);
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  z-index: 2;
  margin: 0;
  font-size: 1.5em;
  font-weight: 400;
  line-height: 1.5em;
  position: sticky;
  top: 0;
  overflow: hidden;
}

.drawer:hover, .drawer:focus {
  background-color: var(--bckgdColor);
  text-decoration: line-through;
}

#headerDrawer {
  float: right;
  visibility: hidden;
  transform: rotate(180deg);
}

#logoBlack {
  background-image: url("https://moroccantapes.b-cdn.net/images/MT-Logo-BW-S.png");
  flex-shrink: 0;
  width: 225px;
  height: 150px;
  margin: 10px auto;
}

#headerContent {
  width: 100%;
  height: calc(100 * var(--vh));
  justify-content: space-between;
  position: absolute;
  top: 0;
}

nav ul li {
  list-style: none;
}

nav ul li a {
  color: var(--textColor);
  cursor: pointer;
  border: none;
  outline: none;
  font-size: 2.1em;
  font-weight: 700;
  line-height: 1em;
  text-decoration: none;
}

.nav-current {
  color: var(--secondaryColor) !important;
  text-decoration: line-through !important;
}

#searchSection {
  flex-grow: 1;
  margin: 10px 0 0;
}

#searchSection form, .search-title {
  margin-left: 40px;
}

.search-titles {
  background-color: var(--bckgdColor);
  width: 100px;
  color: var(--textColor);
  cursor: default;
  margin-bottom: 10px;
  padding: 2px;
  font-size: 1em;
  font-weight: 700;
  line-height: 1.5em;
}

#genreDropDown {
  background-color: var(--bckgdColor);
  width: 180px;
  height: 30px;
  color: var(--secondaryColor);
  border: none;
  border-radius: 6px;
  outline: none;
  margin-bottom: 30px;
  padding: 0 10px;
  font-family: Consolas, sans-serif;
}

#searchWrapper {
  background-color: var(--bckgdColor);
  z-index: 2;
  border-radius: 6px;
  width: 180px;
  height: 30px;
  margin-bottom: 30px;
  margin-left: 40px;
  position: relative;
}

#searchIcon {
  flex-grow: 0;
  margin-left: 3px;
}

#searchWrapper form {
  flex-grow: 1;
}

#searchInput {
  background-color: var(--bckgdColor);
  width: 100%;
  height: 30px;
  color: var(--offWhite);
  border: none;
  border-radius: 6px;
  outline: none;
  padding: 0 10px;
  font-family: Consolas, sans-serif;
  font-size: .7em;
}

#searchWrapper:hover, #searchWrapper #searchInput:focus, #searchInput:focus, #searchWrapper:hover #searchInput {
  background-color: var(--offBckgd);
}

#closeSearch {
  cursor: pointer;
  width: 25px;
  height: 25px;
  fill: var(--offWhite);
  border: none;
  outline: none;
  margin: 0 4px;
}

#searchResults {
  width: calc(100vw - 570px);
  height: 1px;
  max-height: calc(100 * var(--vh));
  z-index: 4;
  visibility: hidden;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  background-color: #000000e6;
  margin: 0 285px;
  position: fixed;
  top: 0;
  overflow-y: scroll;
  box-shadow: 0 1px 5px #000c;
}

.search-header {
  background-color: var(--offBckgd);
  border-bottom: 1px solid var(--sideBarColor);
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  position: sticky;
  top: 0;
}

.search-header-title {
  color: var(--secondaryColor);
  text-transform: uppercase;
  padding: 20px 10px;
  font-size: 1.5em;
  font-weight: 700;
}

#searchValue {
  color: var(--offWhite);
  flex-grow: 1;
  margin-left: 10px;
}

#closeSearchOverlay {
  cursor: pointer;
  width: 24px;
  height: 24px;
}

.search-sb-change {
  width: calc(100% - 315px) !important;
  margin: 0 30px 0 285px !important;
}

.search-results-change {
  visibility: visible !important;
  height: var(--searchHeight) !important;
}

#searchResults .sb-title {
  background-color: var(--bckgdColor);
  flex-grow: 1;
  margin: 0;
}

#searchTitles {
  background-color: var(--offBckgd);
  border-bottom: 1px solid var(--sideBarColor);
  flex-wrap: wrap;
  position: sticky;
  top: 69px;
  box-shadow: inset 0 1px 5px #00000080;
}

.search-result-title {
  text-transform: uppercase;
  background-color: var(--sideBarColor);
  color: var(--secondaryColor);
  cursor: pointer;
  border-radius: 6px;
  margin: 15px 10px;
  padding: 5px 20px;
  font-size: 1em;
  font-weight: 700;
}

.search-result-title-change {
  background-color: var(--secondaryColor) !important;
  color: var(--bckgdColor) !important;
}

.no-result {
  color: var(--textSecondaryColor);
  margin: 20px 0;
  padding: 0 20px;
}

.search-result-item {
  color: var(--textColor);
  cursor: pointer;
  margin: 0;
  padding: 15px 20px;
  font-size: .8em;
  line-height: 2em;
}

.search-result-item:hover, .search-result-item:focus {
  background-color: var(--secondaryColor);
  color: var(--mainColor);
  text-decoration: line-through;
}

.search-result-item:hover mark {
  background-color: var(--offBckgd);
  color: var(--textColor);
}

mark {
  background-color: var(--secondaryColor);
}

.kofi-button:link, .kofi-button:visited {
  background-color: var(--green);
  text-transform: uppercase;
  width: 130px;
  min-width: 130px;
  color: var(--textColor);
  border-radius: 6px;
  gap: 10px;
  margin: 0 0 20px 40px;
  padding: 10px;
  font-family: Consolas, sans-serif;
  font-size: 1em;
  text-decoration: none;
  box-shadow: 0 1px 5px #44a27680;
}

.kofi-button:hover, .kofi-button:focus {
  box-shadow: 0 2px 10px #44a276b3;
}

.kofi-button img {
  width: 22px;
  height: 15px;
  animation: 3s infinite wiggle;
}

.kofi-button span {
  margin-top: 2px;
  font-weight: 700;
}

#aboutContent .kofi-button {
  margin: 20px 0;
}

@keyframes wiggle {
  0% {
    transform: rotate(0)scale(1);
  }

  60% {
    transform: rotate(0)scale(1);
  }

  75% {
    transform: rotate(0)scale(1.12);
  }

  80% {
    transform: rotate(0)scale(1.1);
  }

  84% {
    transform: rotate(-10deg)scale(1.1);
  }

  88% {
    transform: rotate(10deg)scale(1.1);
  }

  92% {
    transform: rotate(-10deg)scale(1.1);
  }

  96% {
    transform: rotate(10deg)scale(1.1);
  }

  100% {
    transform: rotate(0)scale(1);
  }
}

#subscribeBtn {
  background-color: var(--secondaryColor);
  width: 130px;
  height: 1.5em;
  color: var(--mainColor);
  flex-shrink: 0;
  margin: 0 40px;
  padding: 0 10px;
  font-size: 1em;
  font-weight: 700;
  line-height: 1em;
  box-shadow: 0 1px 5px #ffdd6180;
}

#socialsSection {
  margin: 20px 40px;
}

.social-btns {
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  flex-shrink: 0;
  margin-right: 20px;
  padding: 0;
}

.social-icons {
  width: 30px;
  height: 30px;
}

#socialsSection .social-icons path {
  stroke: var(--textColor);
  stroke-width: 1px;
}

footer {
  margin: 0 0 20px 40px;
  font-size: 14px;
}

footer a {
  color: var(--textColor);
  cursor: pointer;
  border: none;
  outline: none;
  margin: 5px 0;
  font-weight: 700;
  text-decoration: none;
  display: block;
}

footer p {
  width: 200px;
  margin-top: 30px;
  padding: 5px 10px;
  font-size: .8em;
  font-weight: 400;
}

#sideBarRight {
  width: 285px;
  height: calc(100 * var(--vh));
  background-color: var(--sideBarColor);
  -webkt-scroll-behavior: smooth;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden scroll;
  box-shadow: -1px 0 5px #00000080;
}

#sbRightDrawer {
  float: left;
}

.drawer span {
  color: var(--secondaryColor);
  font-weight: 700;
}

.sb-title {
  background-color: var(--sideBarColor);
  color: var(--secondaryColor);
  text-transform: uppercase;
  z-index: 1;
  margin-left: 20px;
  padding: 20px;
  font-size: 2em;
  font-weight: 700;
  position: sticky;
  top: 0;
}

#sbRightTitle p {
  color: var(--offWhite);
  text-transform: none;
  margin: 5px 0 0;
  font-size: .5em;
  font-style: italic;
  font-weight: 400;
}

#thumbsWrapper {
  padding-left: 40px;
  position: relative;
}

.textOverImage {
  text-transform: uppercase;
  cursor: pointer;
  width: 225px;
  height: 225px;
  margin-bottom: 20px;
  position: relative;
}

.textOverImage:before, .textOverImage:after {
  color: var(--secondaryColor);
  text-align: center;
  opacity: 0;
  -o-transition: .3s;
  transition: all .3s;
  position: absolute;
  inset: 0;
}

.textOverImage:before {
  content: attr(data-title);
  padding: 20px 8px 0;
  font-size: 1.2em;
  font-weight: 700;
}

.textOverImage:after {
  content: attr(data-album);
  text-transform: none;
  padding: 8em 8px 8px;
  font-size: 1em;
  font-weight: 400;
}

.overlay {
  width: 100%;
  height: calc(100 * var(--vh));
  cursor: pointer;
  z-index: 99;
  background-color: #000000e6;
  position: fixed;
  top: 0;
  left: 0;
}

.loader-wrapper {
  position: absolute;
}

.backdrop-filter {
  background-color: #3b3935e6;
}

.loader {
  width: 64px;
  height: 64px;
  position: absolute;
}

#subscribeOverlay, #refreshOverlay, #errorOverlay {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  transform: translateY(-100%);
}

.subscribe-overlay-wrapper {
  pointer-events: all;
  text-align: center;
  background-color: var(--offBckgd);
  width: calc(100% - 40px);
  max-width: 450px;
  color: var(--textColor);
  -webkit-box-shadow: 0px 1px 5px var(--offBckgd);
  box-shadow: 0px 1px 5px var(--offBckgd);
  border-radius: 8px;
  padding: 20px;
  position: relative;
  max-height: 415px !important;
}

.close-overlay {
  cursor: pointer;
  z-index: 2;
  width: 25px;
  height: 25px;
  position: absolute;
  top: 10px;
  right: 10px;
}

#subscribeOverlay h1 {
  margin-top: 0;
  font-size: 2em;
  font-weight: 700;
}

#subscribeOverlay p {
  margin: 0 0 10px;
  font-size: 1em;
  font-weight: 700;
}

.subscribe-form {
  width: 100%;
  margin-bottom: 20px;
}

.input-fields {
  background-color: var(--textColor);
  width: 100%;
  max-width: 200px;
  height: 40px;
  color: var(--mainColor);
  border: none;
  border-radius: 6px;
  outline: none;
  margin: 10px 0;
  padding: 0 10px;
  font-family: Consolas, sans-serif;
  display: block;
}

#subscribeOverlay .input-fields:hover, #subscribeOverlay .input-fields:focus, #subscribeOverlay .input-fields:active, #unsubscribeForm .input-fields:hover, #unsubscribeForm .input-fields:focus, #unsubscribeForm .input-fields:active {
  background-color: var(--offWhite) !important;
}

.input-fields:hover, .input-fields:focus, .input-fields:active {
  background-color: var(--sideBarColor) !important;
}

select {
  appearance: none;
  background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMC4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCA0LjkgMTAiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQuOSAxMDsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4NCgkuc3Qwe2ZpbGw6I2ZmZmZmZjt9DQo8L3N0eWxlPg0KPHRpdGxlPmFycm93czwvdGl0bGU+DQo8cG9seWdvbiBjbGFzcz0ic3QwIiBwb2ludHM9IjEuNCw0LjcgMi41LDMuMiAzLjUsNC43ICIvPg0KPHBvbHlnb24gY2xhc3M9InN0MCIgcG9pbnRzPSIzLjUsNS4zIDIuNSw2LjggMS40LDUuMyAiLz4NCjwvc3ZnPg==") 95% no-repeat;
}

select:required:invalid {
  color: gray !important;
}

option {
  color: #fff;
}

input:-webkit-autofill {
  -webkit-text-fill-color: var(--mainColor);
  -webkit-box-shadow: 0 0 0px 1000px var(--offWhite) inset;
  box-shadow: 0 0 0px 1000px var(--offWhite) inset;
  -o-transition: background-color 5000s ease-in-out 0s;
  border: none;
  transition: background-color 5000s ease-in-out;
}

input:-webkit-autofill:hover {
  -webkit-text-fill-color: var(--mainColor);
  -webkit-box-shadow: 0 0 0px 1000px var(--offWhite) inset;
  box-shadow: 0 0 0px 1000px var(--offWhite) inset;
  -o-transition: background-color 5000s ease-in-out 0s;
  border: none;
  transition: background-color 5000s ease-in-out;
}

input:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--mainColor);
  -webkit-box-shadow: 0 0 0px 1000px var(--offWhite) inset;
  box-shadow: 0 0 0px 1000px var(--offWhite) inset;
  -o-transition: background-color 5000s ease-in-out 0s;
  border: none;
  transition: background-color 5000s ease-in-out;
}

textarea:-webkit-autofill {
  -webkit-text-fill-color: var(--mainColor);
  -webkit-box-shadow: 0 0 0px 1000px var(--offWhite) inset;
  box-shadow: 0 0 0px 1000px var(--offWhite) inset;
  -o-transition: background-color 5000s ease-in-out 0s;
  border: none;
  transition: background-color 5000s ease-in-out;
}

textarea:-webkit-autofill:hover {
  -webkit-text-fill-color: var(--mainColor);
  -webkit-box-shadow: 0 0 0px 1000px var(--offWhite) inset;
  box-shadow: 0 0 0px 1000px var(--offWhite) inset;
  -o-transition: background-color 5000s ease-in-out 0s;
  border: none;
  transition: background-color 5000s ease-in-out;
}

textarea:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--mainColor);
  -webkit-box-shadow: 0 0 0px 1000px var(--offWhite) inset;
  box-shadow: 0 0 0px 1000px var(--offWhite) inset;
  -o-transition: background-color 5000s ease-in-out 0s;
  border: none;
  transition: background-color 5000s ease-in-out;
}

select:-webkit-autofill {
  -webkit-text-fill-color: var(--mainColor);
  -webkit-box-shadow: 0 0 0px 1000px var(--offWhite) inset;
  box-shadow: 0 0 0px 1000px var(--offWhite) inset;
  -o-transition: background-color 5000s ease-in-out 0s;
  border: none;
  transition: background-color 5000s ease-in-out;
}

select:-webkit-autofill:hover {
  -webkit-text-fill-color: var(--mainColor);
  -webkit-box-shadow: 0 0 0px 1000px var(--offWhite) inset;
  box-shadow: 0 0 0px 1000px var(--offWhite) inset;
  -o-transition: background-color 5000s ease-in-out 0s;
  border: none;
  transition: background-color 5000s ease-in-out;
}

select:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--mainColor);
  -webkit-box-shadow: 0 0 0px 1000px var(--offWhite) inset;
  box-shadow: 0 0 0px 1000px var(--offWhite) inset;
  -o-transition: background-color 5000s ease-in-out 0s;
  border: none;
  transition: background-color 5000s ease-in-out;
}

#subscribeSubmitBtn {
  background-color: var(--secondaryColor);
  width: 100%;
  height: 40px;
  color: var(--bckgdColor);
  padding: 0 2em;
  font-size: 1em;
  font-weight: 700;
  line-height: 1em;
}

.recaptcha-text {
  color: var(--textSecondaryColor);
  font-weight: 700;
  line-height: 1.5em;
  font-size: 12px !important;
}

.recaptcha-text a {
  color: var(--textSecondaryColor);
  text-decoration: underline;
}

#albumOverlay {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.overlay-change {
  transform: none !important;
}

.overlay-change img {
  width: var(--imgWidth) !important;
}

.overlay-wrapper {
  pointer-events: none;
  padding: 0 30px;
}

#albumOverlay img {
  border-radius: 6px;
  width: 0;
  max-width: 1300px;
  height: auto;
}

#albumCaption {
  text-align: center;
  color: var(--secondaryColor);
  margin-top: 10px;
  font-size: 1.5em;
  font-weight: 700;
}

#reloadBtn {
  background-color: var(--secondaryColor);
  width: 100%;
  max-width: 140px;
  height: 40px;
  color: var(--bckgdColor);
  cursor: pointer;
  margin-top: 20px;
  padding: 0 2em;
  font-size: 1em;
  font-weight: 700;
  line-height: 1em;
}

.page {
  width: calc(100vw - calc(2 * var(--mobilePadding)));
  max-width: 715px;
  min-height: calc(100 * var(--vh));
  -webkt-scroll-behavior: smooth;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  color: var(--textColor);
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  margin-bottom: 100px;
  margin-left: 315px;
}

#tapeContent a {
  color: var(--secondaryColor);
  cursor: pointer;
  font-weight: 700;
  text-decoration: underline;
}

.artist-h1 {
  text-transform: uppercase;
  margin: 20px 0 0;
  font-size: 2.2em;
  font-weight: 700;
}

.album-h2 {
  margin: 0 0 20px;
  font-size: 1.5em;
  font-weight: 400;
}

#playAlbumBtn {
  background-color: var(--secondaryColor);
  color: var(--mainColor);
  text-transform: uppercase;
  border-radius: 6px;
  margin: 0 0 20px;
  padding: 10px 20px;
  font-size: 1em;
  font-weight: 700;
  box-shadow: 0 1px 5px #ffdd6180;
}

.album-cover {
  cursor: pointer;
  width: 100%;
  height: auto;
}

.tape-content-wrapper {
  border-bottom: 3px dashed var(--sideBarColor);
  padding-bottom: 20px;
}

#tapeContent h3 {
  color: var(--textSecondaryColor);
  margin: 20px 0;
  font-size: 1em;
  font-weight: 700;
}

.tape-content-read {
  margin: 0;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.5em;
}

.bullet-points {
  color: var(--textColor);
  margin-left: 1em;
  padding: 10px 0;
  font-style: italic;
  display: block;
}

.tape-content-titles {
  margin: 20px 0;
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1.5em;
}

.label {
  display: inline-block;
}

.links {
  cursor: pointer;
}

.tracks-play-svg {
  width: 20px;
  height: 20px;
}

.tracks {
  border-radius: 6px;
  padding: 10px;
}

.tracks span {
  float: right;
  color: var(--bckgdColor);
  visibility: hidden;
}

.tags, .tags:target {
  text-transform: uppercase;
  background-color: var(--secondaryColor);
  color: var(--bckgdColor);
  cursor: pointer;
  border: none;
  border-radius: 6px;
  outline: none;
  margin: 0 10px 10px 0;
  padding: 8px 12px;
  font-family: Consolas, sans-serif;
}

.tracks:hover {
  background-color: var(--secondaryColor) !important;
  color: var(--bckgdColor) !important;
}

.tracks:hover span {
  visibility: visible;
}

.share-box, .download-box {
  background-color: var(--secondaryColor);
  border-radius: 6px;
  align-items: center;
  max-width: 50px;
  height: 50px;
  margin: 20px 0;
  padding: 10px;
}

.share-box {
  margin-left: 50px;
}

.no-hover {
  fill: initial !important;
  stroke-width: initial !important;
}

.share-box:hover {
  max-width: 210px;
}

.download-box:hover {
  max-width: 100px;
}

.grow-wrap {
  display: -ms-grid;
  width: 100%;
  display: grid;
}

.grow-wrap:after {
  content: attr(data-replicated-value) " ";
  white-space: pre-wrap;
  visibility: hidden;
}

.grow-wrap > textarea {
  resize: none;
  overflow: hidden;
}

.grow-wrap > textarea, .grow-wrap:after {
  grid-area: 1 / 1 / 2 / 2;
  margin: 10px 0;
  font-size: .8em;
  height: unset !important;
  padding: .5rem !important;
}

textarea {
  resize: none;
  min-height: 100px !important;
}

::placeholder {
  color: var(--textSecondaryColor);
  opacity: 1;
}

:-ms-input-placeholder {
  color: var(--textSecondaryColor);
}

::-moz-placeholder {
  color: var(--textSecondaryColor);
}

.page input:-webkit-autofill {
  -webkit-text-fill-color: var(--textColor);
  -webkit-box-shadow: 0 0 0px 1000px var(--sideBarColor) inset;
  box-shadow: 0 0 0px 1000px var(--sideBarColor) inset;
  -o-transition: background-color 5000s ease-in-out 0s;
  border: none;
  transition: background-color 5000s ease-in-out;
}

.page input:-webkit-autofill:hover {
  -webkit-text-fill-color: var(--textColor);
  -webkit-box-shadow: 0 0 0px 1000px var(--sideBarColor) inset;
  box-shadow: 0 0 0px 1000px var(--sideBarColor) inset;
  -o-transition: background-color 5000s ease-in-out 0s;
  border: none;
  transition: background-color 5000s ease-in-out;
}

.page input:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--textColor);
  -webkit-box-shadow: 0 0 0px 1000px var(--sideBarColor) inset;
  box-shadow: 0 0 0px 1000px var(--sideBarColor) inset;
  -o-transition: background-color 5000s ease-in-out 0s;
  border: none;
  transition: background-color 5000s ease-in-out;
}

.page textarea:-webkit-autofill {
  -webkit-text-fill-color: var(--textColor);
  -webkit-box-shadow: 0 0 0px 1000px var(--sideBarColor) inset;
  box-shadow: 0 0 0px 1000px var(--sideBarColor) inset;
  -o-transition: background-color 5000s ease-in-out 0s;
  border: none;
  transition: background-color 5000s ease-in-out;
}

.page textarea:-webkit-autofill:hover {
  -webkit-text-fill-color: var(--textColor);
  -webkit-box-shadow: 0 0 0px 1000px var(--sideBarColor) inset;
  box-shadow: 0 0 0px 1000px var(--sideBarColor) inset;
  -o-transition: background-color 5000s ease-in-out 0s;
  border: none;
  transition: background-color 5000s ease-in-out;
}

.page textarea:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--textColor);
  -webkit-box-shadow: 0 0 0px 1000px var(--sideBarColor) inset;
  box-shadow: 0 0 0px 1000px var(--sideBarColor) inset;
  -o-transition: background-color 5000s ease-in-out 0s;
  border: none;
  transition: background-color 5000s ease-in-out;
}

.page select:-webkit-autofill {
  -webkit-text-fill-color: var(--textColor);
  -webkit-box-shadow: 0 0 0px 1000px var(--sideBarColor) inset;
  box-shadow: 0 0 0px 1000px var(--sideBarColor) inset;
  -o-transition: background-color 5000s ease-in-out 0s;
  border: none;
  transition: background-color 5000s ease-in-out;
}

.page select:-webkit-autofill:hover {
  -webkit-text-fill-color: var(--textColor);
  -webkit-box-shadow: 0 0 0px 1000px var(--sideBarColor) inset;
  box-shadow: 0 0 0px 1000px var(--sideBarColor) inset;
  -o-transition: background-color 5000s ease-in-out 0s;
  border: none;
  transition: background-color 5000s ease-in-out;
}

.page select:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--textColor);
  -webkit-box-shadow: 0 0 0px 1000px var(--sideBarColor) inset;
  box-shadow: 0 0 0px 1000px var(--sideBarColor) inset;
  -o-transition: background-color 5000s ease-in-out 0s;
  border: none;
  transition: background-color 5000s ease-in-out;
}

.comment-form {
  background-color: var(--secondaryColor);
  border-radius: 6px 6px 0 0;
  align-items: center;
  width: 100%;
  padding: 15px;
}

.comment-form .input-fields {
  background-color: var(--bckgdColor);
  color: var(--textColor);
}

textarea {
  resize: none;
  max-width: none !important;
  min-height: 60px !important;
  padding: 10px !important;
}

#commentSubmitBtn, #replySubmitBtn, #contactSubmitBtn {
  background-color: var(--bckgdColor);
  width: 100%;
  max-width: 200px;
  height: 40px;
  color: var(--secondaryColor);
  margin: 20px 0 10px;
  padding: 0 2em;
  font-size: 1em;
  font-weight: 700;
  line-height: 1em;
}

#commentCount, #sbRightTitle span {
  color: var(--textSecondaryColor);
  margin-left: 10px;
  font-size: .6em;
  font-weight: 400;
}

#commentsWrapper {
  background-color: var(--sideBarColor);
  border-radius: 0 0 6px 6px;
  margin-bottom: 40px;
}

.comment {
  border-bottom: 1px dashed var(--bckgdColor);
  color: var(--textColor);
  padding: 15px;
}

.comment h1 {
  font-weight: 700;
  margin: 10px 0 0 !important;
  font-size: 2em !important;
}

.comment h2 {
  color: var(--textSecondaryColor);
  margin: 0 0 15px;
  font-style: italic;
  font-size: 1em !important;
  font-weight: 400 !important;
}

.time-zone {
  margin-left: 10px;
  font-size: .7em;
}

.comment p {
  word-break: break-word;
}

.reply-form {
  margin-top: 15px;
}

.reply-comment-btn {
  background-color: var(--secondaryColor);
  width: 100px;
  color: var(--mainColor);
  margin: 15px 0 10px;
  padding: .3em 1em;
  font-size: 1em;
  font-weight: 700;
  line-height: 1em;
}

.reply-submit-btn {
  text-transform: uppercase;
  width: 100%;
  color: var(--textColor);
  margin-top: 10px;
  padding: .3em 1em;
  font-size: 1.2em;
  font-weight: 700;
  line-height: 1.2em;
}

.reply {
  background-color: var(--bckgdColor);
  color: var(--textColor);
  border-radius: 6px;
  margin: 20px;
  padding: 20px;
}

.lds-roller-wrapper {
  padding: 20px;
}

.lds-roller {
  width: 80px;
  height: 80px;
  display: inline-block;
  position: relative;
}

.lds-roller div {
  transform-origin: 40px 40px;
  animation: 1.2s cubic-bezier(.5, 0, .5, 1) infinite lds-roller;
}

.lds-roller div:after {
  content: " ";
  background: var(--secondaryColor);
  border-radius: 50%;
  width: 7px;
  height: 7px;
  margin: -4px 0 0 -4px;
  display: block;
  position: absolute;
}

.lds-roller div:first-child {
  animation-delay: -.036s;
}

.lds-roller div:first-child:after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@-webkit-keyframes lds-roller {
  0% {
    -webkit-transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes lds-roller {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

#audioPlayerWrapper {
  z-index: 1;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  background-color: #161514;
  width: calc(100% - 570px);
  height: 80px;
  margin: 0 285px;
  position: fixed;
  bottom: 0;
}

.audio-player-max {
  height: calc(100 * var(--vh)) !important;
}

.audio-player-change {
  width: calc(100% - 315px) !important;
  margin: 0 30px 0 285px !important;
}

.player-loader {
  width: 30px;
  height: 30px;
  display: inline-block;
  position: relative;
}

.player-loader div {
  border: 3px solid var(--secondaryColor);
  border-color: var(--secondaryColor) transparent transparent transparent;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: 1.2s cubic-bezier(.5, 0, .5, 1) infinite player-loader;
  display: block;
  position: absolute;
}

.player-loader div:first-child {
  animation-delay: -.45s;
}

.player-loader div:nth-child(2) {
  animation-delay: -.3s;
}

.player-loader div:nth-child(3) {
  animation-delay: -.15s;
}

@keyframes player-loader {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

#audioPlayerWrapper .player-loader {
  flex-shrink: 0;
}

#playerMin .player-loader {
  margin-left: 20px;
}

#playerMax {
  height: calc(100 * var(--vh));
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  padding: 15px 0;
}

.player-header {
  align-items: center;
  padding: 0 5%;
}

.big-player-thumb {
  flex-shrink: 0;
  width: 100px;
  height: 100px;
}

.player-info {
  flex-grow: 1;
  margin-left: 5px;
  padding: 0;
  overflow: hidden;
}

.player-album, .player-artist {
  text-transform: uppercase;
  margin: 0;
  padding: 0 5px;
}

.player-album {
  color: var(--secondaryColor);
  font-size: 2em;
  font-weight: 700;
}

.player-artist {
  color: var(--textColor);
  font-size: 1.5em;
  font-weight: 400;
}

#minimizePlayerBtn {
  border: 1px solid var(--textColor);
  border-radius: 50%;
  padding: 5px;
}

.player-tracks-wrapper {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  flex-grow: 1;
  margin: 20px 0;
  padding: 20px 0;
  overflow-y: scroll;
}

.player-tracks {
  border-bottom: 1px solid var(--sideBarColor);
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
}

.player-tracks:hover {
  background-color: var(--sideBarColor);
}

.player-tracks-active {
  box-shadow: inset 0 1px 3px;
  background-color: var(--offWhite) !important;
}

.player-tracks-active .player-track, .player-tracks-active .player-dur {
  color: #000;
}

.player-track {
  color: var(--offWhite);
  cursor: pointer;
  padding: 0 5%;
}

.player-dur {
  color: var(--textColor);
  margin-left: 10px;
  padding-right: 5%;
  font-size: .8em;
  line-height: 2em;
}

.playing {
  background: none;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: flex-end;
  width: 16px;
  height: 16px;
  margin: 0 10px;
}

.playing-bar {
  background: var(--sideBarColor);
  width: 25%;
  height: 100%;
  animation: 1.3s infinite alternate up-and-down;
  display: inline-block;
}

.playing-bar1 {
  height: 60%;
}

.playing-bar2 {
  height: 30%;
  animation-delay: -2.2s;
}

.playing-bar3 {
  height: 75%;
  animation-delay: -3.7s;
}

@keyframes up-and-down {
  10% {
    height: 30%;
  }

  30% {
    height: 100%;
  }

  60% {
    height: 50%;
  }

  80% {
    height: 75%;
  }

  100% {
    height: 60%;
  }
}

.transport-wrapper {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  padding: 0 5%;
}

#playerMax .progress-wrapper {
  margin-top: 10px;
}

.transport-dur {
  color: var(--textColor);
  justify-content: space-between;
  margin-top: 10px;
  font-size: .8em;
}

.transport-controls {
  -ms-grid-row-align: center;
  -ms-grid-column-align: center;
  justify-content: space-between;
  place-self: center;
  width: 75%;
  max-width: 415px;
  margin-top: 10px;
}

#playerMax .player-btns {
  margin: 0;
}

#volumeWrapper {
  margin-top: 20px;
}

#volumeBar {
  -ms-grid-row-align: center;
  -ms-grid-column-align: center;
  place-self: center;
  width: 100%;
  min-width: 70px;
  max-width: 150px;
}

#playerMin {
  height: 100%;
  padding: 5px 20px;
}

.small-player-thumb {
  width: 60px;
  height: 60px;
}

.player-svg {
  width: 30px;
  height: 30px;
}

.player-btns {
  z-index: 1;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  flex-shrink: 0;
  margin-left: 20px;
  padding: 0;
}

.player-btns:hover, .player-btns:focus {
  transform: scale(1.2);
}

.player-middle {
  flex-grow: 1;
  padding: 0 5px;
  overflow: hidden;
}

.player-title {
  color: var(--textColor);
  text-transform: uppercase;
  margin: 10px 0;
  padding: 0 10px;
  font-weight: 700;
}

.marquee {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
}

.marquee span {
  display: inline-block;
}

.marquee-change span {
  animation: 10s linear infinite marqueeAnim;
}

@keyframes marqueeAnim {
  0% {
    transform: translateX(-100%);
  }

  50% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(0);
  }
}

.progress-wrapper {
  align-items: center;
  width: 100%;
  padding: 0 10px;
}

.progress-bar:before {
  content: "";
  width: 100%;
  height: 50px;
  position: absolute;
  top: -25px;
}

.progress-bar {
  background-color: var(--textColor);
  -o-transition: height .2s ease-out;
  width: 100%;
  height: 3px;
  -webkit-box-shadow: inset 0px 0px 2px var(--bckgdColor);
  box-shadow: inset 0px 0px 2px var(--bckgdColor);
  cursor: pointer;
  border-radius: 6px;
  transition: height .2s ease-out;
  position: relative;
}

.progress-bar:hover, .progress-bar:hover .progress {
  height: 5px;
}

.progress-bar:hover .play-head {
  width: 12px;
  height: 12px;
}

.play-head-touch-change {
  transform: scale(2.5);
}

.progress {
  background-color: var(--secondaryColor);
  -o-transition: height .2s ease-out;
  border-radius: 6px;
  width: 0%;
  height: 3px;
  transition: height .2s ease-out;
}

.player-heads {
  background-color: var(--secondaryColor);
  -ms-grid-row-align: center;
  -ms-grid-column-align: center;
  width: 10px;
  height: 10px;
  -webkit-box-shadow: 0px 1px 5px var(--bckgdColor);
  box-shadow: 0px 1px 5px var(--bckgdColor);
  pointer-events: none;
  border-radius: 50%;
  place-self: center;
  margin-left: -5px;
  position: absolute;
}

.play-head {
  -o-transition: transform .2s ease-out;
  transition: transform .2s ease-out;
  left: 0%;
}

#progressDurationMin {
  color: var(--textColor);
  flex-shrink: 0;
  margin-left: 15px;
  font-size: .8em;
}

.player-right {
  position: relative;
}

.volume-wrapper:hover #volumeBarMin {
  width: 70px;
}

.volume-wrapper:after {
  content: "";
  width: 0;
  height: 60px;
  position: absolute;
}

.volume-wrapper:hover:after {
  width: 100%;
}

.volume-bar {
  background-color: var(--textColor);
  z-index: 1;
  cursor: pointer;
  border-radius: 6px;
  width: 0;
  height: 3px;
  margin-left: 20px;
  position: relative;
}

.volume {
  background-color: var(--secondaryColor);
  border-radius: 6px;
  width: 100%;
  height: 3px;
}

.volume-head {
  margin-left: 0;
  position: relative;
}

#expandPlayerBtnMin circle, #expandPlayerBtnMin line {
  stroke: var(--textColor);
  stroke-width: 2px;
  stroke-miterlimit: 10;
  stroke-linejoin: round;
  stroke-linecap: round;
}

#expandPlayerBtnMin line {
  fill: none;
}

.arabic-indic {
  margin: 40px 0;
  list-style-type: arabic-indic;
}

#contactContent ul li {
  color: var(--offWhite);
  margin: 20px 0;
  font-style: italic;
}

.inline-links {
  color: var(--secondaryColor);
  cursor: pointer;
  font-weight: 700;
  text-decoration: underline;
}

.bold-links {
  color: var(--secondaryColor);
  margin: 10px 0;
  font-weight: 700;
  text-decoration: underline;
  display: inline-block;
}

.regular-links, .no-links {
  width: auto;
  color: var(--secondaryColor);
  margin: 10px 0;
  font-weight: 400;
  display: block;
}

.contact-result {
  color: var(--bckgdColor);
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
}

.contact-holes-wrapper {
  justify-content: space-around;
  width: 100%;
}

.contact-holes {
  border: 3px dashed var(--secondaryColor);
  background-color: var(--bckgdColor);
  text-align: center;
  width: 80px;
  height: 80px;
  color: var(--secondaryColor);
  border-radius: 50%;
  font-size: 74px;
  font-weight: 700;
}

@media (width <= 1080px) {
  #searchResults {
    z-index: 5;
    width: 100vw;
    margin: 0;
  }

  .search-results-change {
    height: calc(100 * var(--vh)) !important;
  }

  #headerDrawer {
    visibility: visible;
  }

  .page {
    max-width: unset;
    margin: 0 auto 100px;
  }

  #audioPlayerWrapper {
    width: calc(100% - 60px);
    margin: 0 30px;
  }

  .audio-player-margin-change {
    z-index: 5 !important;
    width: 100% !important;
    margin: 0 !important;
  }
}

@media (width <= 768px) {
  #playerMin .player-loader {
    flex-shrink: 0;
    margin: 0 10px;
  }

  .tracks span {
    display: none;
  }

  #playerMin {
    padding: 5px;
  }

  .small-player-thumb {
    width: 50px;
    height: 50px;
  }

  .player-btns {
    margin-left: 10px;
  }

  .page ul {
    padding-left: 1em;
  }

  .arabic-indic {
    padding-left: 2em !important;
  }
}

@media (width <= 540px) {
  #playerMin .volume-wrapper {
    display: none;
  }

  .reply {
    margin: 10px;
    padding: 10px;
  }

  .comment {
    padding: 10px;
  }

  .comment h1 {
    font-size: 1.2em !important;
  }

  .comment h2 {
    font-size: .6em !important;
  }

  .tape-content-read {
    font-size: 1em;
  }
}

@media (width <= 414px) {
  .download-share-wrapper {
    display: block;
  }

  .share-box {
    margin-left: 0;
  }

  .player-middle {
    place-content: center;
    margin: 0;
  }

  #playerMin .progress-wrapper {
    display: none;
  }
}

@media (hover: hover) {
  nav ul li a:hover, nav ul li a:focus {
    color: var(--secondaryColor);
    text-decoration: line-through;
  }

  select:hover, select:focus {
    background-color: var(--offBckgd) !important;
  }

  #closeSearch:hover path {
    fill: var(--textSecondaryColor);
  }

  .close-overlay:hover path, #closeSearchOverlay:hover path {
    fill: var(--sideBarColor);
  }

  .search-result-title:hover {
    background-color: var(--secondaryColor);
    color: var(--bckgdColor);
  }

  #subscribeBtn:hover, #subscribeBtn:focus {
    background-color: var(--red);
    color: var(--bckgdColor);
    -webkit-box-shadow: 0px 1px 5px var(--red);
    box-shadow: 0px 1px 5px var(--red);
    text-decoration: line-through;
  }

  .social-icons:hover path, .social-icons:focus, path .social-btns:focus .social-icons path {
    fill: none;
    stroke-width: 1px;
  }

  footer a:hover, footer a:focus {
    color: var(--secondaryColor);
    text-decoration: line-through;
  }

  #subscribeSubmitBtn:hover, #subscribeSubmitBtn:focus {
    background-color: var(--red);
    color: var(--textColor);
    text-decoration: line-through;
  }

  #unsubscribeSubmitBtn:hover, #unsubscribeSubmitBtn:focus {
    background-color: var(--offWhite);
  }

  .recaptcha-text a:hover, .recaptcha-text a:focus {
    color: var(--red);
  }

  .textOverImage:hover:before, .textOverImage:hover:after {
    opacity: 1;
  }

  .textOverImage:hover:before {
    background-color: #000000e6;
  }

  #tapeContent a:hover {
    color: var(--red);
    text-decoration: line-through;
  }

  #playAlbumBtn:hover, #playAlbumBtn:focus {
    background-color: var(--red);
    -webkit-box-shadow: 0px 1px 8px var(--red);
    box-shadow: 0px 1px 8px var(--red);
    text-decoration: line-through;
  }

  .links:hover, .links:focus {
    color: var(--secondaryColor);
    text-decoration: line-through;
  }

  .tags:hover, .tags:focus {
    background-color: var(--red);
    text-decoration: line-through;
  }

  .download-box .social-icons:hover path, .share-box .social-icons:hover path {
    stroke: var(--bckgdColor);
  }

  #commentSubmitBtn:hover, #replySubmitBtn:hover, #contactSubmitBtn:hover, #commentSubmitBtn:focus, #replySubmitBtn:focus, #contactSubmitBtn:focus {
    background-color: var(--red);
    color: var(--mainColor);
    text-decoration: line-through;
  }

  .reply-comment-btn:hover, .reply-comment-btn:focus {
    background-color: var(--red);
    text-decoration: line-through;
  }

  .bold-links:hover, .bold-links:focus, .inline-links:hover, .inline-links:focus, .regular-links:hover {
    color: var(--red);
    text-decoration: line-through;
  }

  #box a:hover, #box a:focus, #box a:active {
    color: var(--offWhite);
    text-decoration: line-through;
  }

  #reloadBtn:hover, #reloadBtn:focus, #reloadBtn:active {
    background-color: var(--offWhite);
  }
}

@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .overlay {
    backdrop-filter: blur(10px);
    background-color: #1e1e1e80;
  }

  @media (width <= 1080px) {
    #header {
      backdrop-filter: blur(20px);
      background-color: #3a3834cc;
    }
  }

  .error-overlay {
    backdrop-filter: blur(10px);
    background-color: #fc1d0bbf !important;
  }

  #searchResults {
    backdrop-filter: blur(50px);
    background-color: #000c;
  }

  .textOverImage:hover:before {
    backdrop-filter: blur(10px);
    background-color: #000c;
  }

  .backdrop-filter {
    backdrop-filter: blur(50px);
    background-color: #3b3935b3 !important;
  }

  #audioPlayerWrapper {
    background-color: var(--playerColor);
    backdrop-filter: blur(50px);
  }

  #box {
    backdrop-filter: blur(30px);
    background-color: #3b3935b3 !important;
  }
}
/*# sourceMappingURL=index.e6a55db2.css.map */
