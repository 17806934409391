.btn-container {
    visibility: hidden;
}

@font-face {
    font-family: 'Consolas';
    src: url(https://moroccantapes.b-cdn.net/static/Consolas.ttf) format("truetype");
    ;
    font-weight: 400;
}

@font-face {
    font-family: 'Consolas';
    src: url(https://moroccantapes.b-cdn.net/static/Consolas-Bold.ttf) format("truetype");
    ;
    font-weight: 700;
}

/*  BEGIN GLOBALS */
:root {
    --bckgdColor: #1e1d1c;
    --offBckgd: #161514;
    --sideBarColor: #3a3834;
    --offWhite: #fff6d9;
    --mainColor: #000;
    --secondaryColor: #ffdd63;
    --red: #FC1D0B;
    --green: #44A276;
    --textColor: #fff;
    --textSecondaryColor: #737373;
    --playerColor: #161514bf;
    --mobilePadding: 42px;
    --vh: 1vh;
}

*,
:after,
:before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

*::-webkit-scrollbar {
    display: none;
}

.flex-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.flex-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.flex-col {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.no-display {
    display: none !important;
}

.no-pointers {
    pointer-events: none !important;
}

.no-scroll {
    overflow: hidden !important;
}

.no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.bckg {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.bckg-right {
    background-position: right !important;
}

.ease-out {
    -o-transition: 0.3s ease-out;
    -moz-transition: 0.3s ease-out;
    -webkit-transition: 0.3s ease-out;
    transition: 0.3s ease-out;
}

.cubic-bezier {
    -o-transition: .7s cubic-bezier(0.57, -0.21, 0, 0.74);
    -moz-transition: .7s cubic-bezier(0.57, -0.21, 0, 0.74);
    -webkit-transition: .7s cubic-bezier(0.57, -0.21, 0, 0.74);
    transition: .7s cubic-bezier(0.57, -0.21, 0, 0.74);
}

.error {
    color: var(--red) !important;
}

.btns {
    display: block;
    border: none;
    outline: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-family: 'Consolas', sans-serif;
    cursor: pointer;
}

.box-theme {
    display: block;
    background-color: var(--red);
    text-align: center;
    color: var(--mainColor);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.transform-sbleft {
    -o-transform: translateX(-255px);
    -moz-transform: translateX(-255px);
    -webkit-transform: translateX(-255px);
    -ms-transform: translateX(-255px);
    transform: translateX(-255px);
}

.transform-sbright {
    -o-transform: translateX(255px);
    -moz-transform: translateX(255px);
    -webkit-transform: translateX(255px);
    -ms-transform: translateX(255px);
    transform: translateX(255px);
}

.transform-y-100 {
    -o-transform: translateY(100%);
    -moz-transform: translateY(100%);
    -webkit-transform: translateY(100%);
    -ms-transform: translateY(100%);
    transform: translateY(100%);
}

.transform-header-100 {
    -o-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
}

.transform-sbright-100 {
    -o-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%);
}

.mask-gradient-5 {
    -webkit-mask-image: linear-gradient(to right, transparent 0, var(--playerColor) 5px, var(--playerColor) 80%, transparent 100%);
    mask-image: linear-gradient(to right, transparent 0, var(--playerColor) 5px, var(--playerColor) 80%, transparent 100%);
}

.mask-gradient-20 {
    -webkit-mask-image: linear-gradient(to bottom, transparent 0, var(--playerColor) 20px, var(--playerColor) 80%, transparent 100%);
    mask-image: linear-gradient(to bottom, transparent 0, var(--playerColor) 20px, var(--playerColor) 80%, transparent 100%);
}

.mask-gradient-5-perc {
    -webkit-mask-image: linear-gradient(to right, transparent 0, var(--playerColor) 5%, var(--playerColor) 80%, transparent 100%);
    mask-image: linear-gradient(to right, transparent 0, var(--playerColor) 5%, var(--playerColor) 80%, transparent 100%);
}

button:disabled {
    opacity: 0.8;
    cursor: default;
}

.comment-form .btn-disabled {
    background-color: var(--bckgdColor) !important;
}

#subscribeForm .btn-disabled,
#unsubscribeForm .btn-disabled {
    background-color: var(--secondaryColor) !important;
}

.spinner,
.spinner:before {
    border-radius: 50%;
}

.spinner {
    color: var(--red);
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    -webkit-box-shadow: inset 0 0 0 2px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    overflow: hidden;
}

.comment-form .spinner {
    color: var(--secondaryColor) !important;
}

#subscribeForm .spinner,
#unsubscribeForm .spinner {
    color: var(--bckgdColor) !important;
}

.spinner:before {
    position: absolute;
    content: "";
}

.spinner:before {
    width: 10.4px;
    height: 20.4px;
    background-color: var(--textColor);
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    -webkit-transform-origin: 10.4px 10.2px;
    -ms-transform-origin: 10.4px 10.2px;
    transform-origin: 10.4px 10.2px;
    -webkit-animation: loading 2s infinite ease 1.5s;
    animation: loading 1s infinite linear;
}

.comment-form .spinner:before {
    background-color: var(--bckgdColor) !important;
}

#subscribeForm .spinner:before,
#unsubscribeForm .spinner:before {
    background-color: var(--secondaryColor) !important;
}

@-webkit-keyframes loading {
    0% {
        -o-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -o-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes loading {
    0% {
        -o-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -o-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.grecaptcha-badge {
    opacity: 0;
    z-index: -1;
    right: 0 !important;
}

html,
body {
    margin: 0;
    padding: 0;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    -webkt-scroll-behavior: smooth;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
}

html {
    background-color: var(--bckgdColor);
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    text-size-adjust: 100%;
    font-family: 'Consolas', sans-serif;
}

.error-overlay {
    background-color: var(--red) !important;
}

/* BEGIN 404 PAGE & UNSUBSCRIBE PAGE */
#infoWrapper {
    width: 100vw;
    height: 100vh;
}

#infoWrapper img {
    max-width: 1000px;
    width: 100vw;
}

#boxWrapper {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
}

#box {
    background-color: #3b3935b3 !important;
    border-radius: 6px;
    padding: 20px;
    width: calc(100% - 40px);
    min-height: 1px;
    max-width: 420px;
    text-align: center;
    opacity: 0;
    display: none;
}

#box a:link,
#box a:visited {
    text-decoration: underline;
    font-weight: 700;
    color: var(--secondaryColor);
}

#box h1,
#box h2,
#box p {
    color: var(--textColor);
}

#unsubscribeSubmitBtn {
    width: 100%;
    max-width: 200px;
    height: 40px;
    font-size: 1em;
    font-weight: 700;
    line-height: 1em;
    background-color: var(--secondaryColor);
    color: var(--bckgdColor);
    padding: 0 2em;
}

/* END 404 PAGE & UNSUBSCRIBE PAGE */
/*  END GLOBALS */
#bigLogo {
    width: 100%;
    height: calc(100 * var(--vh));
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

#bigLogo img {
    max-width: 1000px;
    width: calc(100% - 60px);
}

#fixedContainer {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 4;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
}

/*  BEGIN HEADER */
#header {
    width: 285px;
    height: calc(100 * var(--vh));
    position: absolute;
    top: 0;
    left: 0;
    overflow-y: auto;
    background-color: var(--sideBarColor);
    -webkit-box-shadow: 1px 0px 5px rgba(0, 0, 0, 0.5);
    box-shadow: 1px 0px 5px rgba(0, 0, 0, 0.5);
    z-index: 5;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
}

.drawer {
    width: 30px;
    height: calc(100 * var(--vh));
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    -webkit-writing-mode: vertical-rl;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    color: var(--textColor);
    font-size: 1.5em;
    font-weight: 400;
    line-height: 1.5em;
    text-transform: uppercase;
    text-align: center;
    overflow: hidden;
    margin: 0;
    cursor: pointer;
    z-index: 2;
}

.drawer:hover,
.drawer:focus {
    text-decoration: line-through;
    background-color: var(--bckgdColor);
}

#headerDrawer {
    float: right;
    -o-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    visibility: hidden;
}

#logoBlack {
    width: 225px;
    height: 150px;
    background-image: url(https://moroccantapes.b-cdn.net/images/MT-Logo-BW-S.png);
    margin: 10px auto;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

#headerContent {
    position: absolute;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
    height: calc(100 * var(--vh));
    top: 0;
}

nav ul li {
    list-style: none;
}

nav ul li a {
    text-decoration: none;
    color: var(--textColor);
    font-size: 2.1em;
    font-weight: 700;
    line-height: 1em;
    border: none;
    outline: none;
    cursor: pointer;
}

.nav-current {
    color: var(--secondaryColor) !important;
    text-decoration: line-through !important;
}

#searchSection {
    margin: 10px 0px 0px 0px;
    flex-grow: 1;
}

#searchSection form {
    margin-left: 40px;
}

.search-title {
    margin-left: 40px;
}

.search-titles {
    width: 100px;
    font-size: 1em;
    font-weight: 700;
    line-height: 1.5em;
    margin-bottom: 10px;
    padding: 2px;
    background-color: var(--bckgdColor);
    color: var(--textColor);
    cursor: default;
}

#genreDropDown {
    width: 180px;
    height: 30px;
    outline: none;
    border: none;
    border-radius: 6px;
    margin-bottom: 30px;
    font-family: 'Consolas', sans-serif;
    padding: 0 10px;
    background-color: var(--bckgdColor);
    color: var(--secondaryColor);
}

/*  BEGIN SEARCH */
#searchWrapper {
    position: relative;
    width: 180px;
    height: 30px;
    margin-bottom: 30px;
    margin-left: 40px;
    border-radius: 6px;
    background-color: var(--bckgdColor);
    z-index: 2;
}

#searchIcon {
    margin-left: 3px;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
}

#searchWrapper form {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}

#searchInput {
    width: 100%;
    height: 30px;
    border: none;
    outline: none;
    border-radius: 6px;
    font-family: 'Consolas', sans-serif;
    font-size: 0.7em;
    padding: 0 10px;
    background-color: var(--bckgdColor);
    color: var(--offWhite);
}

#searchWrapper:hover,
#searchWrapper #searchInput:focus,
#searchInput:focus,
#searchWrapper:hover #searchInput {
    background-color: var(--offBckgd);
}

#closeSearch {
    width: 25px;
    height: 25px;
    margin: 0px 4px;
    border: none;
    outline: none;
    cursor: pointer;
    fill: var(--offWhite);
}

#searchResults {
    width: calc(100vw - 570px);
    height: 1px;
    max-height: calc(100 * var(--vh));
    margin: 0 285px;
    position: fixed;
    top: 0;
    overflow-y: scroll;
    background-color: rgb(0 0 0/ 90%);
    -webkit-box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.8);
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.8);
    z-index: 4;
    visibility: hidden;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
}

.search-header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background-color: var(--offBckgd);
    border-bottom: 1px solid var(--sideBarColor);
    padding: 0 10px;
}

.search-header-title {
    font-size: 1.5em;
    font-weight: 700;
    color: var(--secondaryColor);
    text-transform: uppercase;
    padding: 20px 10px;
}

#searchValue {
    color: var(--offWhite);
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    margin-left: 10px;
}

#closeSearchOverlay {
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.search-sb-change {
    width: calc(100% - 315px) !important;
    margin: 0 30px 0 285px !important;
}

.search-results-change {
    visibility: visible !important;
    height: var(--searchHeight) !important;
}

#searchResults .sb-title {
    margin: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    background-color: var(--bckgdColor);
}

#searchTitles {
    position: -webkit-sticky;
    position: sticky;
    top: 69px;
    background-color: var(--offBckgd);
    border-bottom: 1px solid var(--sideBarColor);
    -webkit-box-shadow: inset 0px 1px 5px rgba(0, 0, 0, 0.5);
    box-shadow: inset 0px 1px 5px rgba(0, 0, 0, 0.5);
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.search-result-title {
    padding: 5px 20px;
    margin: 15px 10px;
    font-size: 1em;
    font-weight: 700;
    text-transform: uppercase;
    background-color: var(--sideBarColor);
    color: var(--secondaryColor);
    border-radius: 6px;
    cursor: pointer;
}

.search-result-title-change {
    background-color: var(--secondaryColor) !important;
    color: var(--bckgdColor) !important;
}

.no-result {
    color: var(--textSecondaryColor);
    padding: 0 20px;
    margin: 20px 0px;
}

.search-result-item {
    padding: 15px 20px;
    font-size: 0.8em;
    line-height: 2em;
    margin: 0;
    color: var(--textColor);
    cursor: pointer;
}

.search-result-item:hover,
.search-result-item:focus {
    background-color: var(--secondaryColor);
    color: var(--mainColor);
    text-decoration: line-through;
}

.search-result-item:hover mark {
    background-color: var(--offBckgd);
    color: var(--textColor);
}

mark {
    background-color: var(--secondaryColor);
}

/*  END SEARCH */
/*  BEGIN KO-FI BUTTON */
.kofi-button:link,
.kofi-button:visited {
    width: 130px;
    padding: 10px;
    background-color: var(--green);
    -webkit-box-shadow: 0px 1px 5px hsl(152deg 41% 45% / 50%);
    box-shadow: 0px 1px 5px hsl(152deg 41% 45% / 50%);
    font-family: 'Consolas', sans-serif;
    text-transform: uppercase;
    border-radius: 6px;
    margin: 0px 0px 20px 40px;
    min-width: 130px;
    font-size: 1em;
    text-decoration: none;
    color: var(--textColor);
    gap: 10px;
}

.kofi-button:hover,
.kofi-button:focus {
    -webkit-box-shadow: 0px 2px 10px hsl(152deg 41% 45% / 70%);
    box-shadow: 0px 2px 10px hsl(152deg 41% 45% / 70%);
}

.kofi-button img {
    width: 22px;
    height: 15px;
    animation: wiggle 3s infinite;
}

.kofi-button span {
    font-weight: 700;
    margin-top: 2px;
}

#aboutContent .kofi-button {
    margin: 20px 0;
}

@keyframes wiggle {
    0% {
        transform: rotate(0) scale(1);
    }

    60% {
        transform: rotate(0) scale(1);
    }

    75% {
        transform: rotate(0) scale(1.12);
    }

    80% {
        transform: rotate(0) scale(1.1);
    }

    84% {
        transform: rotate(-10deg) scale(1.1);
    }

    88% {
        transform: rotate(10deg) scale(1.1);
    }

    92% {
        transform: rotate(-10deg) scale(1.1);
    }

    96% {
        transform: rotate(10deg) scale(1.1);
    }

    100% {
        transform: rotate(0) scale(1);
    }
}

/*  END KO-FI BUTTON */
#subscribeBtn {
    width: 130px;
    height: 1.5em;
    background-color: var(--secondaryColor);
    color: var(--mainColor);
    font-size: 1em;
    line-height: 1em;
    font-weight: 700;
    margin: 0 40px;
    padding: 0 10px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-box-shadow: 0px 1px 5px #ffdd6180;
    box-shadow: 0px 1px 5px #ffdd6180;
}

#socialsSection {
    margin: 20px 40px;
}

.social-btns {
    border: none;
    outline: none;
    background: none;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding: 0;
    margin-right: 20px;
    cursor: pointer;
}

.social-icons {
    width: 30px;
    height: 30px;
}

#socialsSection .social-icons path {
    stroke: var(--textColor);
    stroke-width: 1px;
}

footer {
    margin: 0px 0px 20px 40px;
    font-size: 14px;
}

footer a {
    display: block;
    text-decoration: none;
    color: var(--textColor);
    font-weight: 700;
    margin: 5px 0;
    border: none;
    outline: none;
    cursor: pointer;
}

footer p {
    width: 200px;
    font-weight: 400;
    font-size: 0.8em;
    margin-top: 30px;
    padding: 5px 10px;
}

/*  END HEADER */
/*  BEGIN SIDEBAR RIGHT */
#sideBarRight {
    width: 285px;
    height: calc(100 * var(--vh));
    position: absolute;
    top: 0;
    right: 0;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: var(--sideBarColor);
    -webkt-scroll-behavior: smooth;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    -webkit-box-shadow: -1px 0px 5px rgba(0, 0, 0, 0.5);
    box-shadow: -1px 0px 5px rgba(0, 0, 0, 0.5);
    -ms-touch-action: pan-y;
    touch-action: pan-y;
}

#sbRightDrawer {
    float: left;
}

.drawer span {
    color: var(--secondaryColor);
    font-weight: 700;
}

.sb-title {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: var(--sideBarColor);
    font-size: 2em;
    font-weight: 700;
    color: var(--secondaryColor);
    text-transform: uppercase;
    padding: 20px;
    margin-left: 20px;
    z-index: 1;
}

#sbRightTitle p {
    margin: 5px 0 0 0;
    color: var(--offWhite);
    font-size: 0.5em;
    font-weight: 400;
    font-style: italic;
    text-transform: none;
}

#thumbsWrapper {
    position: relative;
    padding-left: 40px;
}

.textOverImage {
    width: 225px;
    height: 225px;
    position: relative;
    text-transform: uppercase;
    cursor: pointer;
    margin-bottom: 20px;
}

.textOverImage::before,
.textOverImage:after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: var(--secondaryColor);
    text-align: center;
    opacity: 0;
    -o-transition: 0.3s;
    -moz-transition: 0.3s;
    -webkit-transition: 0.3s;
    -ms-transition: 0.3s;
    transition: 0.3s;
}

.textOverImage:before {
    content: attr(data-title);
    font-size: 1.2em;
    font-weight: 700;
    padding: 20px 8px 0;
}

.textOverImage:after {
    content: attr(data-album);
    text-transform: none;
    font-size: 1em;
    font-weight: 400;
    padding: 8em 8px 8px 8px;
}

/*  END SIDEBAR RIGHT */
/*  BEGIN GLOBAL OVERLAYS */
.overlay {
    width: 100%;
    height: calc(100 * var(--vh));
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgb(0 0 0 / 90%);
    cursor: pointer;
    z-index: 99;
}

.loader-wrapper {
    position: absolute;
}

.backdrop-filter {
    background-color: rgb(59 57 53 / 90%);
}

.loader {
    width: 64px;
    height: 64px;
    position: absolute;
}

#subscribeOverlay,
#refreshOverlay,
#errorOverlay {
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
    -ms-touch-action: pan-y;
    touch-action: pan-y;
}

.subscribe-overlay-wrapper {
    pointer-events: all;
    position: relative;
    width: calc(100% - 40px);
    max-width: 450px;
    max-height: 415px !important;
    text-align: center;
    background-color: var(--offBckgd);
    color: var(--textColor);
    border-radius: 8px;
    padding: 20px;
    -webkit-box-shadow: 0px 1px 5px var(--offBckgd);
    box-shadow: 0px 1px 5px var(--offBckgd);
}

.close-overlay {
    position: absolute;
    width: 25px;
    height: 25px;
    top: 10px;
    right: 10px;
    cursor: pointer;
    z-index: 2;
}

#subscribeOverlay h1 {
    font-size: 2em;
    font-weight: 700;
    margin-top: 0;
}

#subscribeOverlay p {
    font-size: 1em;
    font-weight: 700;
    margin: 0 0 10px 0;
}

.subscribe-form {
    width: 100%;
    margin-bottom: 20px;
}

.input-fields {
    display: block;
    border: none;
    border-radius: 6px;
    outline: none;
    width: 100%;
    max-width: 200px;
    height: 40px;
    background-color: var(--textColor);
    color: var(--mainColor);
    margin: 10px 0;
    padding: 0 10px;
    font-family: 'Consolas', sans-serif;
}

#subscribeOverlay .input-fields:hover,
#subscribeOverlay .input-fields:focus,
#subscribeOverlay .input-fields:active,
#unsubscribeForm .input-fields:hover,
#unsubscribeForm .input-fields:focus,
#unsubscribeForm .input-fields:active {
    background-color: var(--offWhite) !important;
}

.input-fields:hover,
.input-fields:focus,
.input-fields:active {
    background-color: var(--sideBarColor) !important;
}

select {
    /* Remove gloss on safari dropdown and add custom transparent arrows */
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMC4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCA0LjkgMTAiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQuOSAxMDsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4NCgkuc3Qwe2ZpbGw6I2ZmZmZmZjt9DQo8L3N0eWxlPg0KPHRpdGxlPmFycm93czwvdGl0bGU+DQo8cG9seWdvbiBjbGFzcz0ic3QwIiBwb2ludHM9IjEuNCw0LjcgMi41LDMuMiAzLjUsNC43ICIvPg0KPHBvbHlnb24gY2xhc3M9InN0MCIgcG9pbnRzPSIzLjUsNS4zIDIuNSw2LjggMS40LDUuMyAiLz4NCjwvc3ZnPg==) no-repeat 95% 50%;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}

select:required:invalid {
    color: gray !important;
}

option {
    color: white;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: none;
    -webkit-text-fill-color: var(--mainColor);
    -webkit-box-shadow: 0 0 0px 1000px var(--offWhite) inset;
    box-shadow: 0 0 0px 1000px var(--offWhite) inset;
    -o-transition: background-color 5000s ease-in-out 0s;
    -moz-transition: background-color 5000s ease-in-out 0s;
    -webkit-transition: background-color 5000s ease-in-out 0s;
    -ms-transition: background-color 5000s ease-in-out 0s;
    transition: background-color 5000s ease-in-out 0s;
}

#subscribeSubmitBtn {
    width: 100%;
    height: 40px;
    font-size: 1em;
    font-weight: 700;
    line-height: 1em;
    background-color: var(--secondaryColor);
    color: var(--bckgdColor);
    padding: 0 2em;
}

.recaptcha-text {
    font-size: 12px !important;
    font-weight: 700;
    line-height: 1.5em;
    color: var(--textSecondaryColor);
}

.recaptcha-text a {
    text-decoration: underline;
    color: var(--textSecondaryColor);
}

#albumOverlay {
    -ms-touch-action: pan-y;
    touch-action: pan-y;
}

.overlay-change {
    -webkit-transform: none !important;
    -ms-transform: none !important;
    transform: none !important;
}

.overlay-change img {
    width: var(--imgWidth) !important;
}

.overlay-wrapper {
    padding: 0 30px;
    pointer-events: none;
}

#albumOverlay img {
    width: 0;
    height: auto;
    max-width: 1300px;
    border-radius: 6px;
}

#albumCaption {
    font-size: 1.5em;
    font-weight: 700;
    text-align: center;
    color: var(--secondaryColor);
    margin-top: 10px;
}

#reloadBtn {
    width: 100%;
    max-width: 140px;
    height: 40px;
    font-size: 1em;
    font-weight: 700;
    line-height: 1em;
    background-color: var(--secondaryColor);
    color: var(--bckgdColor);
    padding: 0 2em;
    margin-top: 20px;
    cursor: pointer;
}

/*  END GLOBAL OVERLAYS */
/*  BEGIN TAPE CONTENT */
.page {
    width: calc(100vw - calc(2 * var(--mobilePadding)));
    max-width: 715px;
    margin-left: 315px;
    margin-bottom: 100px;
    min-height: calc(100 * var(--vh));
    -webkt-scroll-behavior: smooth;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    color: var(--textColor);
    -ms-touch-action: pan-y;
    touch-action: pan-y;
}

#tapeContent a {
    text-decoration: underline;
    color: var(--secondaryColor);
    font-weight: 700;
    cursor: pointer;
}

.artist-h1 {
    font-size: 2.2em;
    font-weight: 700;
    margin: 20px 0 0 0;
    text-transform: uppercase;
}

.album-h2 {
    font-size: 1.5em;
    font-weight: 400;
    margin: 0 0 20px 0;
}

#playAlbumBtn {
    font-weight: 700;
    font-size: 1em;
    background-color: var(--secondaryColor);
    color: var(--mainColor);
    margin: 0 0 20px 0;
    padding: 10px 20px;
    border-radius: 6px;
    text-transform: uppercase;
    -webkit-box-shadow: 0px 1px 5px #ffdd6180;
    box-shadow: 0px 1px 5px #ffdd6180;
}

.album-cover {
    width: 100%;
    height: auto;
    cursor: pointer;
}

.tape-content-wrapper {
    border-bottom: 3px dashed var(--sideBarColor);
    padding-bottom: 20px;
}

#tapeContent h3 {
    font-size: 1em;
    font-weight: 700;
    margin: 20px 0;
    color: var(--textSecondaryColor);
}

.tape-content-read {
    font-size: 1em;
    font-weight: 400;
    line-height: 1.5em;
    margin: 0;
}

.bullet-points {
    display: block;
    padding: 10px 0;
    margin-left: 1em;
    font-style: italic;
    color: var(--textColor);
}

.tape-content-titles {
    font-size: 1.5em;
    font-weight: 700;
    line-height: 1.5em;
    margin: 20px 0;
}

.label {
    display: inline-block;
}

.links {
    cursor: pointer;
}

.tracks-play-svg {
    width: 20px;
    height: 20px;
}

.tracks {
    padding: 10px;
    border-radius: 6px;
}

.tracks span {
    float: right;
    color: var(--bckgdColor);
    visibility: hidden;
}

.tags,
.tags:target {
    border: none;
    outline: none;
    text-transform: uppercase;
    font-family: 'Consolas', sans-serif;
    background-color: var(--secondaryColor);
    color: var(--bckgdColor);
    border-radius: 6px;
    margin: 0 10px 10px 0;
    padding: 8px 12px;
    cursor: pointer;
}

.tracks:hover {
    background-color: var(--secondaryColor) !important;
    color: var(--bckgdColor) !important;
}

.tracks:hover span {
    visibility: visible;
}

.share-box,
.download-box {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    max-width: 50px;
    height: 50px;
    background-color: var(--secondaryColor);
    border-radius: 6px;
    margin: 20px 0;
    padding: 10px;
}

.share-box {
    margin-left: 50px;
}

.no-hover {
    fill: initial !important;
    stroke-width: initial !important;
}

.share-box:hover {
    max-width: 210px;
}

.download-box:hover {
    max-width: 100px;
}

/*  BEGIN COMMENTS SECTION */
/* BEGIN HANDLE TEXTAREA */
.grow-wrap {
    /* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */
    display: -ms-grid;
    display: grid;
    width: 100%;
}

.grow-wrap::after {
    /* Note the weird space! Needed to preventy jumpy behavior */
    content: attr(data-replicated-value) " ";

    /* This is how textarea text behaves */
    white-space: pre-wrap;

    /* Hidden from view, clicks, and screen readers */
    visibility: hidden;
}

.grow-wrap>textarea {
    /* You could leave this, but after a user resizes, then it ruins the auto sizing */
    resize: none;

    /* Firefox shows scrollbar on growth, you can hide like this. */
    overflow: hidden;
}

.grow-wrap>textarea,
.grow-wrap::after {
    /* Identical styling required!! */
    padding: 0.5rem !important;
    font-size: 0.8em;
    height: unset !important;
    /* Place on top of each other */
    grid-area: 1 / 1 / 2 / 2;
    margin: 10px 0;
}

textarea {
    resize: none;
    min-height: 100px !important;
    /*  padding: 5px 10px !important; */
}

/* END HANDLE TEXTAREA */
::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--textSecondaryColor);
    opacity: 1;
    /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--textSecondaryColor);
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--textSecondaryColor);
}

.page input:-webkit-autofill,
.page input:-webkit-autofill:hover,
.page input:-webkit-autofill:focus,
.page textarea:-webkit-autofill,
.page textarea:-webkit-autofill:hover,
.page textarea:-webkit-autofill:focus,
.page select:-webkit-autofill,
.page select:-webkit-autofill:hover,
.page select:-webkit-autofill:focus {
    border: none;
    -webkit-text-fill-color: var(--textColor);
    -webkit-box-shadow: 0 0 0px 1000px var(--sideBarColor) inset;
    box-shadow: 0 0 0px 1000px var(--sideBarColor) inset;
    -o-transition: background-color 5000s ease-in-out 0s;
    -moz-transition: background-color 5000s ease-in-out 0s;
    -webkit-transition: background-color 5000s ease-in-out 0s;
    -ms-transition: background-color 5000s ease-in-out 0s;
    transition: background-color 5000s ease-in-out 0s;
}

.comment-form {
    width: 100%;
    background-color: var(--secondaryColor);
    border-radius: 6px 6px 0px 0px;
    padding: 15px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.comment-form .input-fields {
    background-color: var(--bckgdColor);
    color: var(--textColor);
}

textarea {
    resize: none;
    max-width: none !important;
    min-height: 60px !important;
    padding: 10px !important;
}

#commentSubmitBtn,
#replySubmitBtn,
#contactSubmitBtn {
    width: 100%;
    max-width: 200px;
    height: 40px;
    font-size: 1em;
    font-weight: 700;
    line-height: 1em;
    background-color: var(--bckgdColor);
    color: var(--secondaryColor);
    padding: 0 2em;
    margin: 20px 0 10px 0;
}

#commentCount,
#sbRightTitle span {
    font-weight: 400;
    font-size: 0.6em;
    color: var(--textSecondaryColor);
    margin-left: 10px;
}

#commentsWrapper {
    border-radius: 0px 0px 6px 6px;
    background-color: var(--sideBarColor);
    margin-bottom: 40px;
}

.comment {
    border-bottom: 1px dashed var(--bckgdColor);
    padding: 15px;
    color: var(--textColor);
}

.comment h1 {
    font-size: 2em !important;
    font-weight: 700;
    margin: 10px 0 0 0 !important;
}

.comment h2 {
    font-size: 1em !important;
    font-weight: 400 !important;
    font-style: italic;
    margin: 0 0 15px;
    color: var(--textSecondaryColor);
}

.time-zone {
    font-size: 0.7em;
    margin-left: 10px;
}

.comment p {
    word-break: break-word;
}

.reply-form {
    margin-top: 15px;
}

.reply-comment-btn {
    width: 100px;
    font-size: 1em;
    font-weight: 700;
    line-height: 1em;
    background-color: var(--secondaryColor);
    color: var(--mainColor);
    padding: 0.3em 1em;
    margin: 15px 0 10px;
}

.reply-submit-btn {
    width: 100%;
    font-size: 1.2em;
    font-weight: 700;
    line-height: 1.2em;
    text-transform: uppercase;
    color: var(--textColor);
    padding: 0.3em 1em;
    margin-top: 10px;
}

.reply {
    background-color: var(--bckgdColor);
    color: var(--textColor);
    margin: 20px;
    padding: 20px;
    border-radius: 6px;
}

/* BEGIN COMMENTS LOADER */
.lds-roller-wrapper {
    padding: 20px;
}

.lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-roller div {
    -webkit-animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
}

.lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: var(--secondaryColor);
    margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
    -webkit-animation-delay: -0.036s;
    animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
}

.lds-roller div:nth-child(2) {
    -webkit-animation-delay: -0.072s;
    animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
}

.lds-roller div:nth-child(3) {
    -webkit-animation-delay: -0.108s;
    animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
}

.lds-roller div:nth-child(4) {
    -webkit-animation-delay: -0.144s;
    animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
}

.lds-roller div:nth-child(5) {
    -webkit-animation-delay: -0.18s;
    animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
}

.lds-roller div:nth-child(6) {
    -webkit-animation-delay: -0.216s;
    animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
}

.lds-roller div:nth-child(7) {
    -webkit-animation-delay: -0.252s;
    animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
}

.lds-roller div:nth-child(8) {
    -webkit-animation-delay: -0.288s;
    animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
}

@-webkit-keyframes lds-roller {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes lds-roller {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* END COMMENTS LOADER */
/*  END COMMENTS SECTION */
/*  END TAPE CONTENT */
/*  BEGIN AUDIO PLAYER */
#audioPlayerWrapper {
    position: fixed;
    width: calc(100% - 570px);
    bottom: 0;
    height: 80px;
    background-color: rgb(22 21 20 / 100%);
    margin: 0 285px;
    z-index: 1;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
}

.audio-player-max {
    height: calc(100 * var(--vh)) !important;
}

.audio-player-change {
    width: calc(100% - 315px) !important;
    margin: 0 30px 0 285px !important;
}

/*  BEGIN PLAYER LOADER */
.player-loader {
    display: inline-block;
    position: relative;
    width: 30px;
    height: 30px;
}

.player-loader div {
    display: block;
    position: absolute;
    width: 30px;
    height: 30px;
    border: 3px solid var(--secondaryColor);
    border-radius: 50%;
    -webkit-animation: player-loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    animation: player-loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--secondaryColor) transparent transparent transparent;
}

.player-loader div:nth-child(1) {
    -webkit-animation-delay: -0.45s;
    animation-delay: -0.45s;
}

.player-loader div:nth-child(2) {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
}

.player-loader div:nth-child(3) {
    -webkit-animation-delay: -0.15s;
    animation-delay: -0.15s;
}

@-webkit-keyframes player-loader {
    0% {
        -o-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -o-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes player-loader {
    0% {
        -o-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -o-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

#audioPlayerWrapper .player-loader {
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

#playerMin .player-loader {
    margin-left: 20px;
}

/*  END PLAYER LOADER */
/*  BEGIN PLAYER MAX */
#playerMax {
    padding: 15px 0;
    height: calc(100 * var(--vh));
    -ms-touch-action: pan-y;
    touch-action: pan-y;
}

.player-header {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 5%;
}

.big-player-thumb {
    width: 100px;
    height: 100px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.player-info {
    margin-left: 5px;
    padding: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    overflow: hidden;
}

.player-album,
.player-artist {
    margin: 0;
    padding: 0 5px;
    text-transform: uppercase;
}

.player-album {
    font-size: 2em;
    font-weight: 700;
    color: var(--secondaryColor);
}

.player-artist {
    font-size: 1.5em;
    font-weight: 400;
    color: var(--textColor);
}

#minimizePlayerBtn {
    border-radius: 50%;
    border: 1px solid var(--textColor);
    padding: 5px;
}

.player-tracks-wrapper {
    margin: 20px 0;
    padding: 20px 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    overflow-y: scroll;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
}

.player-tracks {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1px solid var(--sideBarColor);
    cursor: pointer;
}

.player-tracks:hover {
    background-color: var(--sideBarColor);
}

.player-tracks-active {
    background-color: var(--offWhite) !important;
    -webkit-box-shadow: inset 0px 1px 3px;
    box-shadow: inset 0px 1px 3px;
}

.player-tracks-active .player-track,
.player-tracks-active .player-dur {
    color: black;
}

.player-track {
    color: var(--offWhite);
    padding: 0 5%;
    cursor: pointer;
}

.player-dur {
    color: var(--textColor);
    font-size: 0.8em;
    line-height: 2em;
    margin-left: 10px;
    padding-right: 5%;
}

/* BEGIN PLAYING LOADER */
.playing {
    background: transparent;
    width: 16px;
    height: 16px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    margin: 0 10px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.playing-bar {
    display: inline-block;
    background: var(--sideBarColor);
    width: 25%;
    height: 100%;
    -webkit-animation: up-and-down 1.3s ease infinite alternate;
    animation: up-and-down 1.3s ease infinite alternate;
}

.playing-bar1 {
    height: 60%;
}

.playing-bar2 {
    height: 30%;
    -webkit-animation-delay: -2.2s;
    animation-delay: -2.2s;
}

.playing-bar3 {
    height: 75%;
    -webkit-animation-delay: -3.7s;
    animation-delay: -3.7s;
}

@-webkit-keyframes up-and-down {
    10% {
        height: 30%;
    }

    30% {
        height: 100%;
    }

    60% {
        height: 50%;
    }

    80% {
        height: 75%;
    }

    100% {
        height: 60%;
    }
}

@keyframes up-and-down {
    10% {
        height: 30%;
    }

    30% {
        height: 100%;
    }

    60% {
        height: 50%;
    }

    80% {
        height: 75%;
    }

    100% {
        height: 60%;
    }
}

/* END PLAYING LOADER */
.transport-wrapper {
    padding: 0 5%;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
}

#playerMax .progress-wrapper {
    margin-top: 10px;
}

.transport-dur {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: 10px;
    color: var(--textColor);
    font-size: 0.8em;
}

.transport-controls {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 75%;
    max-width: 415px;
    -ms-grid-row-align: center;
    -ms-grid-column-align: center;
    place-self: center;
    margin-top: 10px;
}

#playerMax .player-btns {
    margin: 0;
}

#volumeWrapper {
    margin-top: 20px;
}

#volumeBar {
    width: 100%;
    min-width: 70px;
    max-width: 150px;
    -ms-grid-row-align: center;
    -ms-grid-column-align: center;
    place-self: center;
}

/*  END PLAYER MAX */
/*  BEGIN PLAYER MIN */
#playerMin {
    padding: 5px 20px;
    height: 100%;
}

.small-player-thumb {
    width: 60px;
    height: 60px;
}

.player-svg {
    width: 30px;
    height: 30px;
}

.player-btns {
    border: none;
    outline: none;
    background: none;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding: 0;
    margin-left: 20px;
    z-index: 1;
    cursor: pointer;
}

.player-btns:hover,
.player-btns:focus {
    -o-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
}

.player-middle {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding: 0 5px;
    overflow: hidden;
}

.player-title {
    color: var(--textColor);
    margin: 10px 0;
    font-weight: 700;
    padding: 0 10px;
    text-transform: uppercase;
}

.marquee {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
}

.marquee span {
    display: inline-block;
}

.marquee-change span {
    -webkit-animation: marqueeAnim 10s infinite linear;
    animation: marqueeAnim 10s infinite linear;
}

@-webkit-keyframes marqueeAnim {
    0% {
        -o-transform: translateX(-100%);
        -moz-transform: translateX(-100%);
        -webkit-transform: translateX(-100%);
        -ms-transform: translateX(-100%);
        transform: translateX(-100%);
    }

    50% {
        -o-transform: translateX(0);
        -moz-transform: translateX(0);
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
    }

    100% {
        -o-transform: translateX(0);
        -moz-transform: translateX(0);
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
    }
}

@keyframes marqueeAnim {
    0% {
        -o-transform: translateX(-100%);
        -moz-transform: translateX(-100%);
        -webkit-transform: translateX(-100%);
        -ms-transform: translateX(-100%);
        transform: translateX(-100%);
    }

    50% {
        -o-transform: translateX(0);
        -moz-transform: translateX(0);
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
    }

    100% {
        -o-transform: translateX(0);
        -moz-transform: translateX(0);
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
    }
}

.progress-wrapper {
    width: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 10px;
}

.progress-bar:before {
    width: 100%;
    height: 50px;
    position: absolute;
    top: -25px;
    content: '';
}

.progress-bar {
    position: relative;
    width: 100%;
    height: 3px;
    background-color: var(--textColor);
    border-radius: 6px;
    -o-transition: height 0.2s ease-out;
    -moz-transition: height 0.2s ease-out;
    -webkit-transition: height 0.2s ease-out;
    transition: height 0.2s ease-out;
    -webkit-box-shadow: inset 0px 0px 2px var(--bckgdColor);
    box-shadow: inset 0px 0px 2px var(--bckgdColor);
    cursor: pointer;
}

.progress-bar:hover,
.progress-bar:hover .progress {
    height: 5px;
}

.progress-bar:hover .play-head {
    width: 12px;
    height: 12px;
}

.play-head-touch-change {
    -o-transform: scale(2.5);
    -moz-transform: scale(2.5);
    -webkit-transform: scale(2.5);
    -ms-transform: scale(2.5);
    transform: scale(2.5);
}

.progress {
    width: 0%;
    height: 3px;
    background-color: var(--secondaryColor);
    border-radius: 6px;
    -o-transition: height 0.2s ease-out;
    -moz-transition: height 0.2s ease-out;
    -webkit-transition: height 0.2s ease-out;
    transition: height 0.2s ease-out;
}

.player-heads {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: var(--secondaryColor);
    border-radius: 50%;
    -ms-grid-row-align: center;
    -ms-grid-column-align: center;
    place-self: center;
    margin-left: -5px;
    -webkit-box-shadow: 0px 1px 5px var(--bckgdColor);
    box-shadow: 0px 1px 5px var(--bckgdColor);
    pointer-events: none;
}

.play-head {
    left: 0%;
    -o-transition: transform 0.2s ease-out;
    -moz-transition: transform 0.2s ease-out;
    -webkit-transition: transform 0.2s ease-out;
    transition: transform 0.2s ease-out;
}

#progressDurationMin {
    font-size: 0.8em;
    color: var(--textColor);
    margin-left: 15px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.player-right {
    position: relative;
}

.volume-wrapper:hover #volumeBarMin {
    width: 70px;
}

.volume-wrapper:after {
    position: absolute;
    content: '';
    width: 0px;
    height: 60px;
}

.volume-wrapper:hover:after {
    width: 100%;
}

.volume-bar {
    position: relative;
    width: 0px;
    height: 3px;
    background-color: var(--textColor);
    border-radius: 6px;
    margin-left: 20px;
    z-index: 1;
    cursor: pointer;
}

.volume {
    width: 100%;
    height: 3px;
    background-color: var(--secondaryColor);
    border-radius: 6px;
}

.volume-head {
    position: relative;
    margin-left: 0;
}

#expandPlayerBtnMin circle,
#expandPlayerBtnMin line {
    stroke: var(--textColor);
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke-linejoin: round;
    stroke-linecap: round;
}

#expandPlayerBtnMin line {
    fill: none;
}

/*  END PLAYER MIN */
/*  END AUDIO PLAYER */
/*  BEGIN CONTACT FORM */
.arabic-indic {
    list-style-type: arabic-indic;
    margin: 40px 0;
}

#contactContent ul li {
    margin: 20px 0;
    font-style: italic;
    color: var(--offWhite);
}

.inline-links {
    font-weight: 700;
    text-decoration: underline;
    color: var(--secondaryColor);
    cursor: pointer;
}

.bold-links {
    display: inline-block;
    margin: 10px 0;
    font-weight: 700;
    text-decoration: underline;
    color: var(--secondaryColor);
}

.regular-links,
.no-links {
    display: block;
    width: auto;
    margin: 10px 0;
    font-weight: 400;
    color: var(--secondaryColor);
}

.contact-result {
    color: var(--bckgdColor);
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
}

.contact-holes-wrapper {
    -ms-flex-pack: distribute;
    justify-content: space-around;
    width: 100%;
}

.contact-holes {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 3px dashed var(--secondaryColor);
    background-color: var(--bckgdColor);
    text-align: center;
    font-size: 74px;
    font-weight: 700;
    color: var(--secondaryColor);
}

/*  END CONTACT FORM */
@media(max-width:1080px) {
    #searchResults {
        width: 100vw;
        margin: 0;
        z-index: 5;
    }

    .search-results-change {
        height: calc(100 * var(--vh)) !important;
    }

    #headerDrawer {
        visibility: visible;
    }

    .page {
        margin: 0 auto 100px;
        max-width: unset;
    }

    #audioPlayerWrapper {
        width: calc(100% - 60px);
        margin: 0 30px;
    }

    .audio-player-margin-change {
        width: 100% !important;
        margin: 0 !important;
        z-index: 5 !important;
    }
}

@media(max-width:768px) {
    #playerMin .player-loader {
        margin: 0px 10px;
        -ms-flex-negative: 0;
        flex-shrink: 0;
    }

    .tracks span {
        display: none;
    }

    #playerMin {
        padding: 5px;
    }

    .small-player-thumb {
        width: 50px;
        height: 50px;
    }

    .player-btns {
        margin-left: 10px;
    }

    .page ul {
        padding-left: 1em;
    }

    .arabic-indic {
        padding-left: 2em !important;
    }
}

@media(max-width:540px) {
    #playerMin .volume-wrapper {
        display: none;
    }

    .reply {
        margin: 10px;
        padding: 10px;
    }

    .comment {
        padding: 10px;
    }

    .comment h1 {
        font-size: 1.2em !important;
    }

    .comment h2 {
        font-size: 0.6em !important;
    }

    .tape-content-read {
        font-size: 1em;
    }
}

@media(max-width:414px) {
    .download-share-wrapper {
        display: block;
    }

    .share-box {
        margin-left: 0px;
    }

    .player-middle {
        place-content: center;
        margin: 0;
    }

    #playerMin .progress-wrapper {
        display: none;
    }
}

/* REMOVE HOVER ON TOUCH DEVICES */
@media(hover:hover) {

    nav ul li a:hover,
    nav ul li a:focus {
        color: var(--secondaryColor);
        text-decoration: line-through;
    }

    select:hover,
    select:focus {
        background-color: var(--offBckgd) !important;
    }

    #closeSearch:hover path {
        fill: var(--textSecondaryColor);
    }

    .close-overlay:hover path,
    #closeSearchOverlay:hover path {
        fill: var(--sideBarColor);
    }

    .search-result-title:hover {
        background-color: var(--secondaryColor);
        color: var(--bckgdColor);
    }

    #subscribeBtn:hover,
    #subscribeBtn:focus {
        background-color: var(--red);
        color: var(--bckgdColor);
        text-decoration: line-through;
        -webkit-box-shadow: 0px 1px 5px var(--red);
        box-shadow: 0px 1px 5px var(--red);
    }

    .social-icons:hover path,
    .social-icons:focus,
    path .social-btns:focus .social-icons path {
        fill: none;
        stroke-width: 1px;
    }

    footer a:hover,
    footer a:focus {
        text-decoration: line-through;
        color: var(--secondaryColor)
    }

    #subscribeSubmitBtn:hover,
    #subscribeSubmitBtn:focus {
        background-color: var(--red);
        color: var(--textColor);
        text-decoration: line-through;
    }

    #unsubscribeSubmitBtn:hover,
    #unsubscribeSubmitBtn:focus {
        background-color: var(--offWhite);
    }

    .recaptcha-text a:hover,
    .recaptcha-text a:focus {
        color: var(--red);
    }

    .textOverImage:hover:before,
    .textOverImage:hover:after {
        opacity: 1;
    }

    .textOverImage:hover:before {
        background-color: rgba(0, 0, 0, 0.9);
    }

    #tapeContent a:hover {
        text-decoration: line-through;
        color: var(--red);
    }

    #playAlbumBtn:hover,
    #playAlbumBtn:focus {
        background-color: var(--red);
        text-decoration: line-through;
        -webkit-box-shadow: 0px 1px 8px var(--red);
        box-shadow: 0px 1px 8px var(--red);
    }

    .links:hover,
    .links:focus {
        text-decoration: line-through;
        color: var(--secondaryColor);
    }

    .tags:hover,
    .tags:focus {
        background-color: var(--red);
        text-decoration: line-through;
    }

    .download-box .social-icons:hover path,
    .share-box .social-icons:hover path {
        stroke: var(--bckgdColor);
    }

    #commentSubmitBtn:hover,
    #replySubmitBtn:hover,
    #contactSubmitBtn:hover,
    #commentSubmitBtn:focus,
    #replySubmitBtn:focus,
    #contactSubmitBtn:focus {
        background-color: var(--red);
        text-decoration: line-through;
        color: var(--mainColor);
    }

    .reply-comment-btn:hover,
    .reply-comment-btn:focus {
        background-color: var(--red);
        text-decoration: line-through;
    }

    .bold-links:hover,
    .bold-links:focus,
    .inline-links:hover,
    .inline-links:focus {
        text-decoration: line-through;
        color: var(--red);
    }

    .regular-links:hover {
        color: var(--red);
        text-decoration: line-through;
    }

    #box a:hover,
    #box a:focus,
    #box a:active {
        text-decoration: line-through;
        color: var(--offWhite);
    }

    #reloadBtn:hover,
    #reloadBtn:focus,
    #reloadBtn:active {
        background-color: var(--offWhite);
    }
}

/* if backdrop support: very transparent and blurred */
@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .overlay {
        background-color: rgb(30 30 30 / 50%);
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
    }

    @media(max-width:1080px) {
        #header {
            background-color: #3a3834cc;
            -webkit-backdrop-filter: blur(20px);
            backdrop-filter: blur(20px);
        }
    }

    .error-overlay {
        background-color: rgba(252 29 11 / 75%) !important;
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
    }

    #searchResults {
        background-color: rgb(0 0 0/ 80%);
        -webkit-backdrop-filter: blur(50px);
        backdrop-filter: blur(50px);
    }

    .textOverImage:hover:before {
        background-color: rgba(0, 0, 0, 0.8);
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
    }

    .backdrop-filter {
        background-color: #3b3935b3 !important;
        -webkit-backdrop-filter: blur(50px);
        backdrop-filter: blur(50px);
    }

    #audioPlayerWrapper {
        background-color: var(--playerColor);
        -webkit-backdrop-filter: blur(50px);
        backdrop-filter: blur(50px);
    }

    #box {
        background-color: #3b3935b3 !important;
        -webkit-backdrop-filter: blur(30px);
        backdrop-filter: blur(30px);
    }
}